import React from 'react'
import '../index.css';
import Navbar from './Navbar/Navbar';
import MainSection from './mainsection/MainSection';
const App = () => {
  return (
    <div>
        <Navbar />
        <MainSection />
    </div>
  )
}

export default App